import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import AppInput from '../../shared/AppInput';
import { INN_MASK } from '../../../const/InputMasks';

interface LoanCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const LoanCreationModal: React.FC<LoanCreationModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [form, setForm] = useState({
    creditor_name: '',
    creditor_inn: '',
    debtor_name: '',
    debtor_inn: '',
    amount: '',
    loan_issue_date: '',
    loan_due_date: '',
    next_payment_date: '',
    next_payment_amount: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    if (!form.creditor_name || !form.debtor_name || !form.amount) {
      toast.error('Заполните обязательные поля');
      return;
    }

    onSubmit(form);
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold mb-4 leading-6 text-gray-900"
                >
                  Создать заем
                </Dialog.Title>
                <div className="mt-2 space-y-4">
                  <AppInput
                    id="inn"
                    name="creditor_name"
                    label="Кредитор"
                    type="text"
                    value={form.creditor_name}
                    onChange={handleChange}
                  />
                  <AppInput
                    id="inn"
                    name="creditor_inn"
                    label="ИНН кредитора"
                    type="text"
                    value={form.creditor_inn}
                    onChange={handleChange}
                    mask={INN_MASK}
                  />
                  <AppInput
                    id="debtor_name"
                    name="debtor_name"
                    label="Заемщик"
                    type="text"
                    value={form.debtor_name}
                    onChange={handleChange}
                  />
                  <AppInput
                    id="inn"
                    name="debtor_inn"
                    label="ИНН заемщика"
                    type="text"
                    value={form.debtor_inn}
                    onChange={handleChange}
                    mask={INN_MASK}
                  />
                  <AppInput
                    type={'number'}
                    id="amount"
                    name="amount"
                    label="Сумма, руб."
                    value={form.amount}
                    onChange={handleChange}
                    min={1}
                  />
                  <AppInput
                    type={'date'}
                    id="loan_issue_date"
                    name="loan_issue_date"
                    label="Дата выдачи"
                    value={form.loan_issue_date}
                    onChange={handleChange}
                  />
                  <AppInput
                    type={'date'}
                    id="loan_due_date"
                    name="loan_due_date"
                    label="Дата возврата"
                    value={form.loan_due_date}
                    onChange={handleChange}
                  />
                  <AppInput
                    type={'number'}
                    id="next_payment_amount"
                    name="next_payment_amount"
                    label="Сумма ближайшей выплаты, руб."
                    value={form.next_payment_amount}
                    onChange={handleChange}
                    min={1}
                  />
                  <AppInput
                    type={'date'}
                    id="next_payment_date"
                    name="next_payment_date"
                    label="Дата ближайшей выплаты"
                    value={form.next_payment_date}
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-4 flex justify-end gap-2">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300"
                    onClick={onClose}
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark"
                    onClick={handleSubmit}
                  >
                    Создать
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoanCreationModal;
