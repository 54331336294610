import React from 'react';
import { Link } from 'react-router-dom';
import AppImportTransactionsDialog from '../../../../components/admin/transactions_flow/AppImportTransactionsDialog';
import { Combobox, Dialog, Tab } from '@headlessui/react';
import { classNames } from '../../../../utils/classNames';
import AppTable from '../../../../components/shared/AppTable';
import { ACCOUNTS_TABLE_HEADERS, REGISTRY_TABLE_HEADERS } from '../../../../const/TableHeaders';
import AppNewBankAccountDialog from '../../../../components/admin/AppNewBankAccountDialog';
import AppCard from '../../../../components/shared/AppCard';
import FloatingBarForTransactions from '../../../../components/charts/interfaces/FloatingBarForTransactions/FloatingBarForTransactions';
import AppEntityPercentageDoughnutChart from '../../../../components/shared/AppEntityPercentageDoughnutChart';
import { DonationStat } from '../../../../types/DonationStat';
import AppNumberWithSpaces from '../../../../components/shared/AppNumberWithSpaces';
import AppQFilter from '../../../../components/admin/AppQFilter';
import { apiWrapper } from '../../../../utils/api';
import { Filter } from '../../../../types/Filter';
import { downloadMinjust } from '../../../../utils/downloadMinjust';
import { downloadUsageReportFund } from '../../../../utils/downloadUsageReportFund';
import { BankAccount } from '../../../../types/BankAccount';
import { Fund } from '../../../../types/Fund';
import { toast } from 'react-toastify';
import './fund-container.scss';
import AccountModalDialogView from '../../../../components/company/account_balance/modal_dialog_view/AccountModalDialogView';
import colors from '../../../../style/colors.json';
import FundReportDownload from '../../../../components/admin/fund/FundReportDownload';

interface FundContainerProps {
  fundId: number | string;
  isFundAccount: boolean;
  connectedFunds: any[];
}

interface FundContainerState {
  isImportTransactionDialogOpen: boolean;
  accounts: string[];
  bank_accounts: BankAccount[];
  donation_stats: DonationStat[];
  isFundAccount: boolean;
  funds: Fund[];
  justiceFilter: Filter | null;
  usageFilter: Filter | null;
  transactionsList: any[] | null;
  isNewBankAccountDialogOpen: boolean;
  income: number | null;
  outcome: number | null;
  currentAmount: number | null;
  mode: 'income' | 'outcome';
  shownTransactionsList: any[];
  incomeTransactions: any[] | null;
  outcomeTransactions: any[] | null;
  payerStats: any;
  payeeStats: any;
  currentAmounts: any[] | null;
  activeFundId: null | string | number;
  generation: number;
  loading: boolean;
  activeTransacton: any;
  isSberModalOpen: boolean;
  query: string;
}

export default class FundContainer extends React.Component<FundContainerProps, FundContainerState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isImportTransactionDialogOpen: false,
      accounts: [],
      bank_accounts: [],
      donation_stats: [],
      isFundAccount: this.props.isFundAccount,
      funds: [],
      justiceFilter: null,
      usageFilter: null,
      transactionsList: null,
      isNewBankAccountDialogOpen: false,
      income: null,
      outcome: null,
      currentAmount: null,
      mode: 'income',
      shownTransactionsList: [],
      incomeTransactions: null,
      outcomeTransactions: null,
      payerStats: {},
      payeeStats: {},
      currentAmounts: null,
      activeFundId: localStorage.getItem('activeFundId')
        ? Number(localStorage.getItem('activeFundId'))
        : Number(props.fundId),
      generation: 0,
      loading: false,
      activeTransacton: null,
      isSberModalOpen: false,
      query: '',
    };

    this.getData = this.getData.bind(this);
    this.importUsingSber = this.importUsingSber.bind(this);
    this.updateFundData = this.updateFundData.bind(this);
  }

  async componentDidMount() {
    if (this.state.activeFundId && !isNaN(Number(this.state.activeFundId))) {
      await this.updateFundData();
    }
  }

  async updateFundData() {
    await this.getBankAccounts();
    await this.getDonationStats();
    await this.getData();
    await this.getFunds();
  }

  async getFunds() {
    await apiWrapper.get('/funds').then((responseData: any) => {
      this.setState({ funds: responseData }, () => {});
    });
  }

  setActiveFundId = (newId: number | string) => {
    localStorage.setItem('activeFundId', String(newId));
    this.setState({ activeFundId: newId }, () => {
      if (!isNaN(Number(newId))) {
        this.updateFundData().then(() => {});
      }
    });
  };

  async getData() {
    let me = this;

    return new Promise((_resolve) => {
      apiWrapper
        .get(`/bank_accounts/transactions?fund_id=${me.state.activeFundId}`)
        .then((responseData: any) => {
          let transactionsList = [...responseData.transactions_list].map((_x) => {
            let newX = { ..._x };
            newX.realAmount = newX.amount + 0;
            return newX;
          });

          let incomeTransactions = transactionsList
            ? transactionsList.filter((_x: any) => {
                return _x.realAmount >= 0;
              })
            : [];
          let outcomeTransactions = transactionsList
            ? transactionsList.filter((_x: any) => {
                return _x.realAmount < 0;
              })
            : [];

          // console.error(transactionsList);
          this.setState(
            {
              transactionsList: transactionsList,
              incomeTransactions: incomeTransactions,
              outcomeTransactions: outcomeTransactions,
              income: responseData.income,
              outcome: responseData.outcome,
              currentAmount: responseData.current_amount,
              currentAmounts: responseData.current_amounts,
            },
            () => {
              let payerStats = {} as any;
              let payeeStats = {} as any;
              for (let transaction of incomeTransactions) {
                if (transaction.amount <= 0) continue;

                if (payerStats[transaction.payer]) {
                  payerStats[transaction.payer] += transaction.amount;
                } else {
                  payerStats[transaction.payer] = transaction.amount;
                }
              }
              for (let transaction of outcomeTransactions) {
                // if (transaction.amount > 0) continue;

                if (payeeStats[transaction.payee]) {
                  payeeStats[transaction.payee] += Math.abs(transaction.amount);
                } else {
                  payeeStats[transaction.payee] = Math.abs(transaction.amount);
                }
              }
              me.setState({ payerStats: payerStats, payeeStats: payeeStats }, () => {
                _resolve(true);
              });
            },
          );
        });
    });
  }

  getDonationStats = async (): Promise<void> => {
    let me = this;

    await apiWrapper
      .get(`/donation_stats/top?fund_id=${me.state.activeFundId}`)
      .then((responseData: any) => {
        this.setState({
          donation_stats: [...responseData],
        });
      });
  };

  handleOpenImportDialog = (): void => {
    this.setState({ isImportTransactionDialogOpen: true });
  };

  handleCloseImportDialog = (): void => {
    let me = this;
    me.setState(
      {
        isImportTransactionDialogOpen: false,
      },
      () => {
        me.getData().then(() => {
          me.setState({
            generation: me.state.generation + 1,
          });
        });
      },
    );
  };

  handleSubmitTransactionsImport = async (): Promise<void> => {
    let me = this;
    me.setState(
      {
        isImportTransactionDialogOpen: false,
      },
      () => {
        me.getData().then(() => {
          me.setState(
            {
              generation: me.state.generation + 1,
            },
            async () => {
              await this.getBankAccounts();
              await this.getDonationStats();
              await this.getData();
            },
          );
        });
      },
    );
  };

  getBankAccounts = async (): Promise<void> => {
    let me = this;

    return new Promise((_resolve) => {
      apiWrapper
        .get(`/bank_accounts?fund_id=${me.state.activeFundId}`)
        .then((responseData: any) => {
          this.setState(
            {
              bank_accounts: [...responseData],
            },
            () => {
              _resolve();
            },
          );
        });
    });
  };

  setJusticeFilter = (newState: Filter): void => {
    this.setState(
      {
        justiceFilter: {
          year: newState.year,
          q: newState.q,
          fund_id: newState.fund_id,
        },
      },
      async () => {
        if (this.state.justiceFilter?.fund_id) {
          await downloadMinjust(this.state.justiceFilter.fund_id);
        }
      },
    );
  };

  setUsageFilter = (newState: Filter): void => {
    this.setState(
      {
        usageFilter: {
          year: newState.year,
          q: newState.q,
          fund_id: newState.fund_id,
        },
      },
      async () => {
        if (this.state.usageFilter?.fund_id) {
          await downloadUsageReportFund(this.state.usageFilter.fund_id);
        }
      },
    );
  };

  importUsingSber() {
    let me = this;
    me.setState({ loading: true }, () => {
      apiWrapper
        .get(`/bank_accounts/refresh_with_business_api?fund_id=${me.props.fundId}`)
        .then((_res: any) => {
          me.getData().then(() => {
            me.setState({ loading: false }, () => {
              toast(_res.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000,
                type: toast.TYPE.INFO,
                pauseOnHover: true,
              });
            });
          });
        });
    });
  }

  openSberModal = () => {
    this.setState({ isSberModalOpen: true });
  };

  closeSberModal = () => {
    this.setState({ isSberModalOpen: false });
  };

  render() {
    const { isSberModalOpen } = this.state;
    let me = this;
    let topMenu = <></>;
    if (typeof me.props.fundId === 'string') {
      let allFunds = [];
      for (let _f of me.props.connectedFunds as any[]) {
        for (let _nestedFund of _f.funds) {
          allFunds.push(_nestedFund);
        }
      }
      // topMenu = (
      //   <div>
      //     <div className={`mb-3 flex flex-row uppercase font-semibold text-[14px]`}>
      //       {allFunds.length > 0 ? (
      //         allFunds
      //           .sort((a: any, b: any) => a?.sort_order - b?.sort_order)
      //           .map((_f, _fIndex) => (
      //             <div
      //               key={_f.id}
      //               className={`fund-button border border-[#C5C4D7] px-4 py-2 cursor-pointer ${
      //                 this.state.activeFundId === _f.id
      //                   ? 'bg-[#E65100] text-white'
      //                   : 'bg-white text-black'
      //               }`}
      //               onClick={() => {
      //                 if (_f.id) {
      //                   this.setActiveFundId(_f.id);
      //                 }
      //               }}
      //             >
      //               {_f.name}
      //               {_f.funds && _f.funds.length > 1 ? `(${_f.funds.length})` : ''}
      //             </div>
      //           ))
      //       ) : (
      //         <div className="w-full text-center text-gray-500 text-sm font-semibold">
      //           На данный момент организации данного типа в АИС «Цифровой фонд» не зарегистрированы
      //         </div>
      //       )}
      //     </div>
      //   </div>
      // );

      if (allFunds.length > 3) {
        // Filter funds by user input (if desired)
        const filteredFunds =
          this.state.query === ''
            ? allFunds
            : allFunds.filter((fund) =>
                fund.name.toLowerCase().includes(this.state.query.toLowerCase()),
              );

        // Render a Combobox (Headless UI example)
        topMenu = (
          <div className="mb-3 text-[14px] font-semibold uppercase w-full">
            <label className="block mb-1 text-gray-700" htmlFor="fund-combobox">
              Выберите фонд
            </label>

            <Combobox
              value={allFunds.find((f) => f.id === this.state.activeFundId)}
              onChange={(selectedFund: Fund) => {
                if (selectedFund?.id) {
                  this.setActiveFundId(selectedFund.id);
                }
              }}
            >
              <div className="relative">
                <Combobox.Input
                  id="fund-combobox"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-[#E65100] focus:border-[#E65100]"
                  displayValue={(fund: Fund) => fund?.name || ''}
                  onChange={(e) => {
                    this.setState({ query: e.target.value });
                  }}
                  placeholder="Начните ввод..."
                />
                <Combobox.Button className="group absolute inset-y-0 right-0 px-2.5">
                  <i className={`far fa-chevron-down`}></i>
                </Combobox.Button>
                <Combobox.Options className="absolute mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto z-10">
                  {filteredFunds.length === 0 && (
                    <div className="cursor-default select-none p-2 text-gray-500">
                      Ничего не найдено
                    </div>
                  )}
                  {filteredFunds.map((fund) => (
                    <Combobox.Option
                      key={fund.id}
                      value={fund}
                      className={({ active }) =>
                        `cursor-pointer select-none p-2 ${
                          active ? 'bg-[#E65100] text-white' : 'bg-white text-black'
                        }`
                      }
                    >
                      {fund.name}
                      {fund.funds && fund.funds.length > 1 ? ` (${fund.funds.length})` : ''}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            </Combobox>
          </div>
        );
      } else {
        // Keep original inline buttons if <= 3 funds
        topMenu = (
          <div className="mb-3 flex flex-row uppercase font-semibold text-[14px]">
            {allFunds.length > 0 ? (
              allFunds.map((_f) => (
                <div
                  key={_f.id}
                  className={`fund-button border border-[#C5C4D7] px-4 py-2 cursor-pointer ${
                    this.state.activeFundId === _f.id
                      ? 'bg-[#E65100] text-white'
                      : 'bg-white text-black'
                  }`}
                  onClick={() => {
                    if (_f.id) {
                      this.setActiveFundId(_f.id);
                    }
                  }}
                >
                  {_f.name}
                  {_f.funds && _f.funds.length > 1 ? `(${_f.funds.length})` : ''}
                </div>
              ))
            ) : (
              <div className="w-full text-center text-gray-500 text-sm font-semibold">
                На данный момент организации данного типа в АИС «Цифровой фонд» не зарегистрированы
              </div>
            )}
          </div>
        );
      }
    }

    let dataNotOk = me.state.bank_accounts.length === 0;
    let noDataEl = (
      <h3 className={` font-semibold py-2 px-5 rounded-xl uppercase text-sm`}>Нет данных</h3>
    );
    // if (me.state.bank_accounts.length === 0 ) {
    //   return <h3 className={`bg-primary mt-3 text-white font-semibold py-2 px-5 rounded-xl uppercase text-sm`}>Нет данных</h3>
    // }

    if (typeof me.state.activeFundId === 'string') return topMenu;

    return (
      <>
        {topMenu}
        <div
          style={me.state.loading ? { opacity: 0.7, pointerEvents: 'none' } : {}}
          className={`bg-white p-3 rounded-xl`}
          key={`fund-${me.props.fundId}-gen-${me.state.generation}`}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
              Общий баланс поступлений и расходов
            </div>

            <div
              className={`${
                this.state.currentAmount ? 'text-white' : 'text-black'
              } font-semibold py-2 px-5 rounded-xl uppercase text-sm`}
              style={{
                backgroundColor: this.state.currentAmount
                  ? this.state.currentAmount > 0
                    ? '#17A63F'
                    : '#B02A2A'
                  : '#fff',
                color: !this.state.currentAmount ? 'black' : '#fff',
              }}
            >
              Баланс&nbsp;
              {`${
                this.state.currentAmount !== null && !dataNotOk
                  ? `${this.state.currentAmount.toLocaleString()} руб`
                  : dataNotOk
                  ? '-'
                  : `Вычисляется`
              }`}
            </div>

            <div
              // style={dataNotOk ? { opacity: 0.5, pointerEvents: 'none' } : {}}
              className={`flex flex-row items-center content-center text-sm`}
            >
              <div className={`flex flex-row items-center content-center text-sm`}>
                <button
                  // onClick={me.importUsingSber}
                  onClick={me.openSberModal}
                  className={`${
                    me.state.loading ? 'pulsing' : ''
                  } border h-[32px] border-[#11BF6A] rounded-xl py-1 px-1`}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/logos/logo-sber.png'}
                    alt="sber.svg"
                    style={{ height: '100%', width: 'auto' }}
                  />
                </button>
              </div>

              <div
                onClick={this.handleOpenImportDialog}
                className="ml-2 uppercase text-white bg-[#E65100] font-semibold py-2 px-5 rounded-xl
                          flex flex-row items-center cursor-pointer"
              >
                Импортировать банковские транзакции
                <i className="fas fa-file-plus ml-2"></i>
              </div>
              <div className={`ml-5`}>
                <Link to={`/admin/balance?fund_id=${me.state.activeFundId}`}>
                  <i className="far fa-expand-arrows text-[#06F] text-[24px]"></i>
                </Link>
              </div>
            </div>
          </div>

          <AppImportTransactionsDialog
            isOpen={this.state.isImportTransactionDialogOpen}
            onClose={this.handleCloseImportDialog}
            onSubmit={this.handleSubmitTransactionsImport}
            accounts={this.state.bank_accounts}
          />

          <div className="w-full py-2 sm:px-0">
            {dataNotOk ? (
              noDataEl
            ) : (
              <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-slate-200 p-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                        selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                      )
                    }
                  >
                    <div className="flex flex-col items-center uppercase font-semibold">
                      Реестр поступлений
                      <span className="text-[#17A63F]">
                        +{' '}
                        {this.state.income !== null
                          ? `${this.state.income.toLocaleString()} руб`
                          : 'Вычисляется'}
                      </span>
                    </div>
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2',
                        selected ? 'bg-white shadow' : 'hover:bg-white/[0.12]',
                      )
                    }
                  >
                    <div className="flex flex-col items-center uppercase font-semibold">
                      Реестр расходов
                      <span className="text-[#B02A2A]">
                        -{' '}
                        {this.state.outcome !== null
                          ? `${this.state.outcome.toLocaleString()} руб`
                          : 'Вычисляется'}
                      </span>
                    </div>
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel>
                    <div key={me.state.mode} className={`border border-[#CCC] rounded-xl mt-2`}>
                      {me.state.incomeTransactions && (
                        <AppTable
                          onClick={(_e, _eIndex) => {
                            me.setState({
                              activeTransacton: _e.other_data,
                            });
                          }}
                          enableSort={true}
                          key={`incomes-${me.state.incomeTransactions?.length}`}
                          headers={REGISTRY_TABLE_HEADERS}
                          data={me.state.incomeTransactions.map((_x) => {
                            let _res = { ..._x };
                            _x.amount = Math.abs(_res.amount);
                            return _res;
                          })}
                        />
                      )}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div
                      key={`outcomes-${me.state.outcomeTransactions?.length}`}
                      className={`border border-[#CCC] rounded-xl mt-2`}
                    >
                      {me.state.outcomeTransactions && (
                        <AppTable
                          enableSort={true}
                          headers={REGISTRY_TABLE_HEADERS}
                          onClick={(_e, _eIndex) => {
                            me.setState({
                              activeTransacton: _e.other_data,
                            });
                          }}
                          data={me.state.outcomeTransactions.map((_x) => {
                            let _res = { ..._x };
                            _x.amount = Math.abs(_res.amount);
                            return _res;
                          })}
                        />
                      )}
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            )}
          </div>
        </div>

        <div className={`w-full mt-3 bg-white p-3 rounded-xl`}>
          <div className="flex flex-row items-center justify-between">
            <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
              Расчетные счета в банках
            </div>
            <div>
              <Link to={`/admin/bank_accounts?fund_id=${this.state.activeFundId}`}>
                <i className="far fa-expand-arrows text-[#06F] text-[24px]"></i>
              </Link>
            </div>
          </div>

          <div className={`border border-[#CCC] rounded-xl mt-3`}>
            <>
              <AppNewBankAccountDialog
                isOpen={this.state.isNewBankAccountDialogOpen}
                fundId={Number(me.state.activeFundId)}
                onClose={() => {
                  this.setState({ isNewBankAccountDialogOpen: false });
                }}
                onSubmit={() => {
                  document.location.reload();
                }}
              />
              {me.state.bank_accounts.length === 0 && (
                <button
                  onClick={() => {
                    this.setState({ isNewBankAccountDialogOpen: true });
                  }}
                  className={`ml-1 mt-1 text-[12px] bg-[#007A22] rounded-lg text-white uppercase font-semibold py-2 px-3 leading-tight`}
                >
                  Добавить банковский счет
                  <i className="fas fa-file-plus ml-2"></i>
                </button>
              )}
              {dataNotOk ? (
                noDataEl
              ) : (
                <AppTable
                  key={me.state.bank_accounts ? me.state.bank_accounts.length : 0}
                  headers={ACCOUNTS_TABLE_HEADERS}
                  data={me.state.bank_accounts}
                />
              )}
            </>
          </div>
        </div>
        <div className={`w-full  mt-3 bg-white p-3 rounded-xl`}>
          <div className="flex flex-row items-center justify-between">
            <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
              Аналитические отчеты
            </div>
            {/*<div>*/}
            {/*  <Link to={`/ais/admin/bank_accounts`}>*/}
            {/*    <i className="far fa-expand-arrows text-[#06F] text-[24px]"></i>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          {dataNotOk ? (
            noDataEl
          ) : (
            <div className="grid grid-cols-12 gap-x-2 gap-y-2 mt-3">
              <div className="col-span-12">
                <AppCard
                  noPadding={true}
                  title={`Движение средств`}
                  key={JSON.stringify(me.state.transactionsList)}
                  content={
                    me.state.transactionsList ? (
                      <>
                        <FloatingBarForTransactions data={me.state.transactionsList} />
                      </>
                    ) : (
                      <p>Загрузка данных</p>
                    )
                  }
                />
              </div>
              <div className="col-span-6 space-y-2">
                <AppCard
                  title={`Распределение поступлений по плательщикам`}
                  content={
                    <div>
                      {Object.keys(me.state.payerStats).length > 0 ? (
                        <AppEntityPercentageDoughnutChart
                          data={{
                            labels: Object.keys(me.state.payerStats).map((_x) =>
                              _x.replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО'),
                            ),
                            datasets: [
                              {
                                label: 'руб',
                                data: Object.keys(me.state.payerStats).map(
                                  (_x) => me.state.payerStats[_x],
                                ),
                                backgroundColor: colors.slice(
                                  0,
                                  Object.keys(me.state.payerStats).length,
                                ),
                                borderColor: colors
                                  .slice(0, Object.keys(me.state.payerStats).length)
                                  .map((_x) => '#ffffff'),
                                borderWidth: 1,
                              },
                            ],
                          }}
                        />
                      ) : (
                        <p>Подсчет данных</p>
                      )}
                    </div>
                  }
                ></AppCard>
                <AppCard
                  title={`ТОП пожертвований`}
                  content={
                    <div className={`w-full flex flex-col space-y-2`}>
                      {this.state.donation_stats
                        .sort((a, b) => Number(b.total_amount) - Number(a.total_amount))
                        .map((d: DonationStat, index: number) => (
                          <div
                            key={index}
                            className={`grid grid-cols-12 text-xl font-semibold border border-[#C9C8D6] rounded p-2 gap-x-2`}
                          >
                            <div className="col-span-1 text-center text-primary">#{index + 1}</div>
                            <div className="col-span-7 text-[#2C2C2C]">{d.company_name}</div>
                            <div className="col-span-4 text-right text-primary">
                              <AppNumberWithSpaces value={Number(d.total_amount)} />
                              &nbsp;₽
                            </div>
                          </div>
                        ))}
                    </div>
                  }
                ></AppCard>
              </div>
              <div className="col-span-6 space-y-2">
                <AppCard
                  title={`Распределение расходов (Топ 8)`}
                  content={
                    <div>
                      {Object.keys(me.state.payeeStats).length > 0 ? (
                        (() => {
                          const topPayeeStats = Object.entries(me.state.payeeStats)
                            .sort(([, a], [, b]) => Number(b) - Number(a))
                            .slice(0, 8); // Take the top 10

                          const labels = topPayeeStats.map(([key]) =>
                            key.replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО'),
                          );
                          const data = topPayeeStats.map(([, value]) => value);

                          return (
                            <AppEntityPercentageDoughnutChart
                              data={{
                                labels,
                                datasets: [
                                  {
                                    label: 'руб',
                                    data,
                                    backgroundColor: colors.slice(0, topPayeeStats.length),
                                    borderColor: colors
                                      .slice(0, topPayeeStats.length)
                                      .map(() => '#ffffff'),
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                            />
                          );
                        })()
                      ) : (
                        <p>Подсчет данных</p>
                      )}
                    </div>
                  }
                />

                <AppCard
                  title={`ТОП расходов (Топ 5)`}
                  content={
                    <div className={`w-full flex flex-col space-y-2`}>
                      {Object.keys(me.state.payeeStats).length > 0 ? (
                        Object.entries(me.state.payeeStats)
                          .sort(([, a], [, b]) => Number(b) - Number(a)) // Sort by expense amount descending
                          .slice(0, 5) // Take the top 10
                          .map(([name, amount], index) => (
                            <div
                              key={index}
                              className={`grid grid-cols-12 text-xl font-semibold border border-[#C9C8D6] rounded p-2 gap-x-2`}
                            >
                              <div className="col-span-1 text-center text-primary">
                                #{index + 1}
                              </div>
                              <div className="col-span-7 text-[#2C2C2C]">
                                {name.replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО')}
                              </div>
                              <div className="col-span-4 text-right text-primary">
                                <AppNumberWithSpaces value={Number(amount)} />
                                &nbsp;₽
                              </div>
                            </div>
                          ))
                      ) : (
                        <p className="text-center text-gray-500">Нет данных</p>
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
        {!dataNotOk && this.state.isFundAccount && (
          <div className={`w-full mt-3 bg-white p-3 rounded-xl`}>
            <div className="flex flex-row items-center justify-between">
              <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
                Нормативная отчетность
              </div>
              {/*<div>*/}
              {/*  <Link to={`/ais/admin/bank_accounts`}>*/}
              {/*    <i className="far fa-expand-arrows text-[#06F] text-[24px]"></i>*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>

            <FundReportDownload activeFundId={Number(this.state.activeFundId)} />

            <div className={`border border-[#C5C4D7] rounded-lg p-5 mt-4`}>
              <div className="flex flex-row items-center justify-between mb-4">
                <div className="uppercase text-white bg-primary font-semibold py-2 px-5 rounded-xl text-sm">
                  Отчет для Управления Министерства юстиции РФ по субъекту РФ
                </div>
              </div>
              <AppQFilter funds={this.state.funds} onApplyFilter={this.setJusticeFilter} />
              {!this.state.justiceFilter && (
                <div className={`flex flex-row items-center justify-center w-3/4 mx-auto py-20`}>
                  <div className={`mr-4`}>
                    <i className="fas fa-exclamation-circle fa-5x text-[#0F094F] opacity-60"></i>
                  </div>
                  <div>
                    <p className={`text-[38px] text-[#0F094F] font-semibold leading-10 opacity-60`}>
                      Выберите период для формирования отчета по&nbsp;форме&nbsp;Минюста&nbsp;РФ
                    </p>
                  </div>
                </div>
              )}
              {/*{this.state.justiceFilter !== null && this.state.justiceFilter.fund_id > 0 && (*/}
              {/*  <button onClick={() => downloadMinjust(this.state.justiceFilter.fund_id)}>*/}
              {/*    скачать отчет в минюст*/}
              {/*  </button>*/}
              {/*)}*/}
            </div>

            {/*<div className={`border border-[#C5C4D7] rounded-lg p-5 mt-4`}>*/}
            {/*  <div className="flex flex-row items-center justify-between mb-4">*/}
            {/*    <div className="uppercase text-white bg-sky-900 font-semibold py-2 px-5 rounded-xl text-sm">*/}
            {/*      Отчет по сводному реестру расходов*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <AppQFilter*/}
            {/*    funds={this.state.funds}*/}
            {/*    bgColor={`bg-sky-900`}*/}
            {/*    onApplyFilter={this.setUsageFilter}*/}
            {/*  />*/}
            {/*  {!this.state.justiceFilter && (*/}
            {/*    <div className={`flex flex-row items-center justify-center w-3/4 mx-auto py-20`}>*/}
            {/*      <div className={`mr-4`}>*/}
            {/*        <i className="fas fa-exclamation-circle fa-5x text-[#0F094F] opacity-60"></i>*/}
            {/*      </div>*/}
            {/*      <div>*/}
            {/*        <p className={`text-[38px] text-[#0F094F] font-semibold leading-10 opacity-60`}>*/}
            {/*          Выберите период для формирования отчета по Фондам «Сведения об осуществленных*/}
            {/*          расходах»*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        )}
        {me.state.activeTransacton && (
          <AccountModalDialogView
            accountTransaction={me.state.activeTransacton}
            open={!!me.state.activeTransacton}
            onClose={() => {
              me.setState({ activeTransacton: null });
            }}
          />
        )}

        {/* Modal */}
        {isSberModalOpen && (
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            open={isSberModalOpen}
            onClose={this.closeSberModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

              {/* Centering Modal Content */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900">
                  СБЕРБАНК БИЗНЕС ОНЛАЙН
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-xl text-gray-500">
                    Для автоматической загрузки данных по указанному счёту из сервиса «СБЕРБАНК
                    БИЗНЕС ОНЛАЙН» необходимо продление действия ключа обмена данными между
                    системами по решению федерального руководства ПАО «Сбербанк»
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={this.closeSberModal}
                  >
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
}
