import React, { Fragment, useEffect, useState } from 'react';
import Dashboard from '../../../containers/admin/Dashboard';
import { Transition } from '@headlessui/react';
import { apiWrapper } from '../../../utils/api';
import dayjs from 'dayjs';
import AppNumberWithSpaces from '../../../components/shared/AppNumberWithSpaces';
import LoanDonationTransferModal from '../../../components/admin/loan/LoanDonationTransferModal';
import { toast } from 'react-toastify';
import LoanCreationModal from '../../../components/admin/loan/LoanCreationModal';
import LoanRepaymentsModal from '../../../components/admin/loan/LoanRepaymentsModal';

interface LoanOrganization {
  inn: string;
  name: string;
}

interface ApiResponse {
  unique_creditors: LoanOrganization[];
  unique_debtors: LoanOrganization[];
}

interface Loan {
  id: number;
  amount: string;
  creditor_name: string;
  creditor_inn: string;
  debtor_name: string;
  debtor_inn: string;
  loan_issue_date: string;
  loan_due_date: string;
  next_payment_date: string;
  next_payment_amount: string;
  status: string;
  direction: string;
  loan_donation_transfer?: LoanTransfer;
}

interface ApiResponseLoans {
  creditor_loans: Loan[];
  debtor_loans: Loan[];
}

interface LoanTransfer {
  created_at: string;
  file_name: string;
  file_url: string;
  id: number;
  justification: string;
}

const DebtRegistryPage: React.FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredCreditors, setFilteredCreditors] = useState<LoanOrganization[]>([]);
  const [filteredDebtors, setFilteredDebtors] = useState<LoanOrganization[]>([]);
  const [uniqueCreditors, setUniqueCreditors] = useState<LoanOrganization[]>([]);
  const [uniqueDebtors, setUniqueDebtors] = useState<LoanOrganization[]>([]);
  const [isCreditorView, setIsCreditorView] = useState<boolean>(true);
  const [selectedEntity, setSelectedEntity] = useState<LoanOrganization | null>(null);
  const [tableFilter, setTableFilter] = useState<string>('');
  const [loans, setLoans] = useState<ApiResponseLoans | null>(null);
  const [isModalTransferOpen, setIsModalTransferOpen] = useState<boolean>(false);
  const [isModalLoanOpen, setIsModalLoanOpen] = useState<boolean>(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan | null>(null);
  const [isModalRepaymentOpen, setIsModalRepaymentOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchUniqueOrganizations(year).then(() => {});
    // eslint-disable-next-line
  }, [year]);

  const fetchUniqueOrganizations = async (year: number) => {
    setLoading(true);
    try {
      // @ts-ignore
      const response: ApiResponse = await apiWrapper.get(`/loans/unique_entities?year=${year}`);
      setUniqueCreditors([...response.unique_creditors]);
      setFilteredCreditors([...response.unique_creditors]);
      setUniqueDebtors([...response.unique_debtors]);
      setFilteredDebtors([...response.unique_debtors]);
    } catch (error) {
      console.error('Failed to fetch unique organizations:', error);
    } finally {
      setLoading(false);
      console.log(uniqueCreditors);
    }
  };

  const fetchLoans = async (inn: string) => {
    setLoading(true);
    try {
      // @ts-ignore
      const response: ApiResponseLoans = await apiWrapper.get(`/loans/search_by_inn?inn=${inn}`);
      setLoans(response);
    } catch (error) {
      console.error('Failed to fetch loans by inn:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLoans = (): ApiResponseLoans => {
    const keyword = tableFilter.toLowerCase();

    const creditor_loans =
      loans?.creditor_loans.filter((loan) => {
        return (
          loan.creditor_inn?.toLowerCase().includes(keyword) ||
          loan.creditor_name?.toLowerCase().includes(keyword) ||
          loan.debtor_inn?.toLowerCase().includes(keyword) ||
          loan.debtor_name?.toLowerCase().includes(keyword)
        );
      }) || [];

    const debtor_loans =
      loans?.debtor_loans.filter((loan) => {
        return (
          loan.creditor_inn?.toLowerCase().includes(keyword) ||
          loan.creditor_name?.toLowerCase().includes(keyword) ||
          loan.debtor_inn?.toLowerCase().includes(keyword) ||
          loan.debtor_name?.toLowerCase().includes(keyword)
        );
      }) || [];

    return { creditor_loans, debtor_loans };
  };

  const handleOpenTransferModal = (loan: Loan) => {
    setSelectedLoan(loan);
    setIsModalTransferOpen(true);
  };

  const handleCloseTransferModal = () => {
    setSelectedLoan(null);
    setIsModalTransferOpen(false);
  };

  const handleSubmitTransferModal = async (data: { justification: string; file: File }) => {
    if (selectedLoan) {
      const formData = new FormData();
      formData.append('loan_id', String(selectedLoan.id));
      formData.append('justification', data.justification);
      if (data.file) {
        formData.append('file', data.file, data.file.name);
      }

      try {
        await apiWrapper
          .postFile(`/loans/${selectedLoan.id}/transfer_to_donation`, formData)
          .then((resp) => {
            // @ts-ignore
            if (resp?.loan) {
              // @ts-ignore
              const updatedLoan = resp.loan as Loan;

              // Update the state with the updated loan
              setLoans((prevLoans) => {
                if (!prevLoans) return prevLoans;

                const updatedCreditorLoans = prevLoans.creditor_loans.map((loan) =>
                  loan.id === updatedLoan.id ? updatedLoan : loan,
                );

                const updatedDebtorLoans = prevLoans.debtor_loans.map((loan) =>
                  loan.id === updatedLoan.id ? updatedLoan : loan,
                );

                return {
                  creditor_loans: updatedCreditorLoans,
                  debtor_loans: updatedDebtorLoans,
                };
              });
            }
          });
      } catch (error) {
        console.error('Failed to transfer loan to donation:', error);
      }
    }
  };

  const handleOpenRepaymentModal = (loan: Loan) => {
    setSelectedLoan(loan);
    setIsModalRepaymentOpen(true);
  };

  const handleCloseRepaymentModal = () => {
    setSelectedLoan(null);
    setIsModalRepaymentOpen(false);
  };

  return (
    <Dashboard
      content={
        <div className={`pt-10 pb-4 px-4 bg-slate-200 overflow-y-scroll`}>
          <div
            className="grid grid-cols-12 gap-2 overflow-y-scroll"
            style={{ height: 'calc(100vh - 180px)' }}
          >
            <div className="col-span-3 flex flex-col" style={{ height: 'calc(100vh - 180px)' }}>
              <div className="sticky top-0 z-10 bg-white">
                <div className="w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase">
                  Последние {isCreditorView ? 'кредиторы' : 'заемщики'}
                </div>

                <div className="p-2">
                  <div className="mb-4">
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                      value={year}
                      onChange={(e) => setYear(Number(e.target.value))}
                    >
                      {Array.from(
                        { length: new Date().getFullYear() - 2023 + 1 },
                        (_, i) => 2023 + i,
                      ).map((_, i) => {
                        const optionYear = new Date().getFullYear() - i;
                        return (
                          <option key={optionYear} value={optionYear}>
                            {optionYear}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder="Поиск по названию или ИНН"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                      value={searchKeyword}
                      onChange={(e) => {
                        const keyword = e.target.value.toLowerCase();
                        setSearchKeyword(e.target.value);

                        if (keyword === '') {
                        } else {
                          if (isCreditorView) {
                            const filtered = uniqueCreditors.filter(
                              (_x) =>
                                _x.name.toLowerCase().includes(keyword) || _x.inn.includes(keyword),
                            );
                            setFilteredCreditors(filtered);
                          } else {
                            const filtered = uniqueDebtors.filter(
                              (_x) =>
                                _x.name.toLowerCase().includes(keyword) || _x.inn.includes(keyword),
                            );
                            setFilteredDebtors(filtered);
                          }
                        }
                      }}
                    />
                    <button
                      className="mt-2 px-4 py-2 bg-gray-300 text-black rounded-lg text-sm hover:bg-gray-400 w-full"
                      onClick={() => {
                        setSearchKeyword('');
                      }}
                    >
                      Очистить фильтр
                    </button>
                  </div>

                  <button
                    className="px-4 py-2 bg-primary w-full text-white rounded-lg text-sm"
                    onClick={() => setIsCreditorView(!isCreditorView)}
                  >
                    {isCreditorView ? 'Отобразить по кредиторам' : 'Отобразить по заемщикам'}
                    <i className="fas fa-sliders-h ml-2"></i>
                  </button>
                </div>
              </div>

              {/* Scrollable list */}
              <div className="overflow-y-auto flex-grow bg-white">
                {(isCreditorView ? filteredCreditors : filteredDebtors).map((entity, index) => (
                  <div
                    key={index}
                    className={`py-2 px-4 border-b cursor-pointer ${
                      (!isCreditorView && selectedEntity?.inn === entity.inn) ||
                      (isCreditorView && selectedEntity?.inn === entity.inn)
                        ? 'bg-[#EDEEF5] font-semibold'
                        : 'hover:bg-[#EDEEF5]'
                    }`}
                    onClick={() => {
                      setSelectedEntity(entity);
                      fetchLoans(entity?.inn);
                    }}
                  >
                    <div className="font-bold">{entity.name || 'Неизвестно'}</div>
                    <div className="text-sm text-gray-500">ИНН: {entity.inn || 'Неизвестно'}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-9">
              <Transition
                as={Fragment}
                show={!loading}
                enter="transform transition duration-[400ms]"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={`h-full`}>
                  <div
                    className={`w-full text-white text-sm text-center p-2 font-semibold bg-primaryGradient rounded-t-lg uppercase`}
                  >
                    {selectedEntity ? (
                      <>
                        {selectedEntity.name} (ИНН: {selectedEntity.inn})
                      </>
                    ) : (
                      'Выберите организацию'
                    )}
                  </div>
                  <div
                    style={{ background: 'rgba(59, 54, 109, 0.15)' }}
                    className="w-full text-sm text-center p-2 font-semibold uppercase text-[#3B366D]"
                  >
                    Учет долговых обязательств
                  </div>

                  <div className="w-full text-sm text-center p-2 space-x-2">
                    <button
                      type="button"
                      className={`px-4 py-2 uppercase rounded-md font-semibold text-xs ${
                        isCreditorView ? 'bg-primary text-white' : 'bg-[#BDBDBD] text-[#626262]'
                      }`}
                      onClick={() => {
                        setIsCreditorView(true);
                      }}
                    >
                      Реестр долгов организации
                    </button>
                    <button
                      type="button"
                      className={`px-4 py-2 uppercase rounded-md font-semibold text-xs ${
                        !isCreditorView ? 'bg-primary text-white' : 'bg-[#BDBDBD] text-[#626262]'
                      }`}
                      onClick={() => {
                        setIsCreditorView(false);
                      }}
                    >
                      Реестр выданных займов организации
                    </button>
                  </div>

                  <div className="w-full text-sm p-2 space-x-2">
                    <button
                      className="bg-primary text-white rounded-md px-4 py-2 text-sm uppercase font-semibold mb-4"
                      onClick={() => setIsModalLoanOpen(true)}
                    >
                      Создать заем
                    </button>
                  </div>

                  {/* Search table */}
                  <div className={`bg-white h-full p-4`}>
                    {/*<div className="flex flex-row items-center px-1">button</div>*/}
                    <div className="mb-4 mt-4 px-1 flex flex-row items-center gap-x-2">
                      <div className={'flex w-full'}>
                        <input
                          type="text"
                          placeholder="Поиск по наименования или по ИНН..."
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                          value={tableFilter}
                          onChange={(e) => setTableFilter(e.target.value)}
                        />
                      </div>
                      <div className={'flex'}>
                        <button
                          type="button"
                          className={`btn bg-label text-white px-2 py-1.5 rounded-lg`}
                          onClick={() => setTableFilter('')}
                        >
                          Очистить
                        </button>
                      </div>
                    </div>
                    <div
                      className="overflow-auto h-full"
                      style={{ maxHeight: 'calc(100vh - 200px)' }}
                    >
                      <table
                        className="mt-4 min-w-full table-auto border-collapse"
                        style={{ tableLayout: 'fixed' }}
                      >
                        <thead className="bg-gray-100 sticky top-0 z-10">
                          <tr className="text-center">
                            <th className="px-4 py-2 border text-sm font-semibold">№</th>
                            <th className="px-4 py-2 border text-sm font-semibold">
                              ИНН Кредитора
                            </th>
                            <th className="px-4 py-2 border text-sm font-semibold">Кредитор</th>
                            <th className="px-4 py-2 border text-sm font-semibold">ИНН Заемщика</th>
                            <th className="px-4 py-2 border text-sm font-semibold">Заемщик</th>
                            <th className="px-4 py-2 border text-sm font-semibold">Сумма, руб.</th>
                            <th className="px-4 py-2 border text-sm font-semibold">
                              Дата возврата займа
                            </th>
                            <th className="px-4 py-2 border text-sm font-semibold">
                              Ближайшая выплата
                            </th>
                            <th className="px-4 py-2 border text-sm font-semibold">
                              Сумма ближайшей выплаты
                            </th>
                            <th className="px-4 py-2 border text-sm font-semibold">
                              Управление займом
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(isCreditorView
                            ? filteredLoans().creditor_loans
                            : filteredLoans().debtor_loans
                          ).map((loan, index) => (
                            <tr key={index}>
                              <td className="px-4 py-2 border text-sm text-center">{index + 1}</td>
                              <td className="px-4 py-2 border text-sm text-center">
                                {loan.creditor_inn}
                              </td>
                              <td className="px-4 py-2 border text-sm">{loan.creditor_name}</td>
                              <td className="px-4 py-2 border text-sm text-center">
                                {loan.debtor_inn}
                              </td>
                              <td className="px-4 py-2 border text-sm">{loan.debtor_name}</td>
                              <td className="px-4 py-2 border text-sm text-center">
                                <AppNumberWithSpaces value={Number(loan.amount)} />
                                &nbsp;₽
                              </td>
                              <td className="px-4 py-2 border text-sm text-center">
                                {dayjs(loan.loan_due_date).format('DD.MM.YYYY')}
                              </td>
                              <td className="px-4 py-2 border text-sm text-center">
                                {dayjs(loan.next_payment_date).format('DD.MM.YYYY')}
                              </td>
                              <td className="px-4 py-2 border text-sm text-center">
                                <AppNumberWithSpaces value={Number(loan.next_payment_amount)} />
                                &nbsp;₽
                              </td>
                              <td className="px-4 py-2 border text-sm">
                                {loan.status === 'transferred' && (
                                  <div className="flex items-center flex-col gap-x-2 gap-y-2 text-center">
                                    <div className={`leading-tight`}>
                                      {loan.loan_donation_transfer?.justification}
                                    </div>
                                    <div>
                                      <a
                                        href={`${loan.loan_donation_transfer?.file_url}`}
                                        download={true}
                                        className="text-primary"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="fas fa-file-alt"></i>
                                        &nbsp;
                                        <span className="underline">
                                          {loan.loan_donation_transfer?.file_name}
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                )}
                                {loan.status !== 'transferred' && (
                                  <div className="flex items-center flex-col lg:flex-row gap-x-2 gap-y-2">
                                    <button
                                      type="button"
                                      className="bg-[#E85A02] text-white rounded-md p-2 uppercase text-xs font-semibold"
                                      aria-label="определить сроки платежей"
                                      onClick={() => handleOpenRepaymentModal(loan)}
                                    >
                                      определить&nbsp;сроки платежей
                                    </button>
                                    <button
                                      type="button"
                                      className="bg-[#007A22] text-white rounded-md p-2 uppercase text-xs font-semibold"
                                      aria-label="перевод в пожертвование"
                                      onClick={() => handleOpenTransferModal(loan)}
                                    >
                                      перевод&nbsp;в пожертвование
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* modal for transfer loan to donation */}
                      <LoanDonationTransferModal
                        isOpen={isModalTransferOpen}
                        onClose={handleCloseTransferModal}
                        onSubmit={handleSubmitTransferModal}
                      />

                      {/* modal to create new loan */}
                      <LoanCreationModal
                        isOpen={isModalLoanOpen}
                        onClose={() => setIsModalLoanOpen(false)}
                        onSubmit={async (data) => {
                          try {
                            await apiWrapper.post('/loans', { loan: data });
                            toast.success('Заем успешно создан');
                            fetchUniqueOrganizations(year);
                          } catch (error) {
                            console.error('Failed to create loan:', error);
                            toast.error('Ошибка при создании займа');
                          }
                        }}
                      />
                      <LoanRepaymentsModal
                        isOpen={isModalRepaymentOpen}
                        onClose={handleCloseRepaymentModal}
                        loanId={selectedLoan?.id || null}
                      />
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      }
    ></Dashboard>
  );
};

export default DebtRegistryPage;
