import { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './AppHeaderBtn.module.scss';

interface AppHeaderBtnProps {
  title?: string;
  icon?: string;
  to: string;
  childPages?: string[];
}

class AppHeaderBtn extends Component<AppHeaderBtnProps, any> {
  render() {
    const { title, icon, to } = this.props;
    const isActive =
      window.location.pathname === to || this.props.childPages?.includes(window.location.pathname);

    return (
      <div>
        <Link to={to}>
          <button
            type="button"
            className={`border-none flex flex-row items-center py-1 px-4 text-sm rounded-xl w-full xl:w-min ${
              isActive ? 'bg-[#E65100] text-white' : 'bg-white text-primary'
            }`}
          >
            {icon && <i data-testid="header-btn-icon" className={`mr-2 text-lg ${icon}`}></i>}
            {title && (
              <span
                data-testid="header-btn-title"
                className={` font-semibold uppercase text-left leading-tight ${styles.headerButtonText}`}
              >
                {title}
              </span>
            )}
          </button>
        </Link>
      </div>
    );
  }
}

export default AppHeaderBtn;
