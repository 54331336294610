import React, { Component } from 'react';
import AppHeader from '../../../components/shared/AppHeader';

interface DashboardProps {
  content: React.ReactNode;
}

class Dashboard extends Component<DashboardProps> {
  render() {
    const { content } = this.props;

    return (
      <div className="max-h-screen flex flex-col">
        <AppHeader />
        <main className="flex-1">
          {/* Show stub for mobile */}
          <div
            className="flex lg:hidden items-center justify-center text-center bg-gray-100 text-gray-700 text-lg font-semibold"
            style={{ height: ' 100dvh' }}
          >
            Мобильная версия программного продукта находится на модернизации. Пожалуйста,
            воспользуйтесь программным продуктом с персонального компьютера или ноутбука.
          </div>
          <div className="hidden lg:block">{content}</div>
        </main>
      </div>
    );
  }
}

export default Dashboard;
