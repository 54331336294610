import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import AppFileInput from '../../shared/AppFileInput';

interface LoanDonationTransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { justification: string; file: File }) => void;
}

const LoanDonationTransferModal: React.FC<LoanDonationTransferModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [justification, setJustification] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = () => {
    if (!justification || !file) {
      toast.error('Введите основание и прикрепите документ');
      return;
    }
    onSubmit({ justification, file });
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="text-center">
                  <i className="fas fa-info-square text-[#E85A02] fa-4x"></i>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-xl text-center leading-6 uppercase font-semibold mt-4"
                >
                  Перевод задолженности в пожертвование
                </Dialog.Title>
                <div className="text-center mt-4 uppercase font-medium text-base">
                  Введите основание и прикрепите подтверждающий документ
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">Основание</label>
                  <input
                    type="text"
                    value={justification}
                    onChange={(e) => setJustification(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Прикрепить документ
                  </label>
                  <AppFileInput onChange={handleFileChange} accept=".pdf,.doc,.docx" />
                </div>
                <div className="mt-6 flex justify-end gap-2">
                  <button
                    type="button"
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300"
                    onClick={onClose}
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark"
                    onClick={handleSubmit}
                  >
                    Сохранить
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoanDonationTransferModal;
