import React, { Component, Fragment } from 'react';
import { BankAccount } from '../../../types/BankAccount';
import { Dialog, Transition } from '@headlessui/react';
import AppInput from '../../shared/AppInput';
import { BIK_MASK, CORR_ACCOUNT_MASK, PAYMENT_ACCOUNT_MASK } from '../../../const/InputMasks';
import { validateInput } from '../../../utils/validations';
import { BANK_ACCOUNT_REGEX, BIK_REGEX, INN_REGEX } from '../../../const/RegexValidations';
import { apiWrapper } from '../../../utils/api';

interface AppNewBankAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  fundId?: number | string;
}

interface AppNewBankAccountDialogState extends BankAccount {
  errors: { [key: string]: string };
}

class AppNewBankAccountDialog extends Component<
  AppNewBankAccountDialogProps,
  AppNewBankAccountDialogState
> {
  constructor(props: AppNewBankAccountDialogProps) {
    super(props);

    this.state = {
      bank_name: '',
      corr_account: '',
      payment_account: '',
      inn: '',
      bank_bik: '',
      opening_date: '',
      errors: {},
      fund_id: -1,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      AppNewBankAccountDialogState,
      keyof AppNewBankAccountDialogState
    >);
  };

  onCloseHandler = (): void => {
    this.setState({
      bank_name: '',
      corr_account: '',
      payment_account: '',
      inn: '',
      bank_bik: '',
      opening_date: '',
      fund_id: Number(this.props.fundId),
    });
    this.props.onClose();
  };

  handleSubmit = async (): Promise<void> => {
    let me = this;
    let payload = { ...me.state } as any;
    payload['fund_id'] = Number(me.props.fundId);

    const { bank_name, corr_account, payment_account, inn, bank_bik, opening_date } = this.state;

    let errors: { [key: string]: string } = {};

    const fields = {
      bank_name,
      corr_account,
      payment_account,
      inn,
      bank_bik,
      opening_date,
    };

    const regexps = {
      inn: INN_REGEX,
      bank_bik: BIK_REGEX,
      corr_account: BANK_ACCOUNT_REGEX,
      payment_account: BANK_ACCOUNT_REGEX,
    };

    for (let field in fields) {
      // @ts-ignore
      const error = validateInput(field, fields[field], regexps[field]);
      if (error) {
        errors[field] = error;
      }
    }

    this.setState({ errors });

    if (Object.keys(errors).length > 0) {
      return;
    }

    await apiWrapper.post('/bank_accounts', payload).then(() => {
      this.props.onSubmit();
      this.onCloseHandler();
    });
  };

  render() {
    const { isOpen } = this.props;
    const { errors } = this.state;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={this.onCloseHandler}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[28px] font-semibold leading-6 text-gray-900"
                  >
                    Добавление банковского счета
                  </Dialog.Title>

                  <div className="mt-10 grid grid-cols-12 gap-x-5">
                    <div className="col-span-12 lg:col-span-6 space-y-4">
                      <AppInput
                        id="bank_name"
                        name="bank_name"
                        label="Наименование банка"
                        type="text"
                        value={this.state.bank_name}
                        onChange={this.handleInputChange}
                      />
                      {errors.bank_name && (
                        <p className="text-red-500 text-sm">{errors.bank_name}</p>
                      )}

                      <AppInput
                        id="inn"
                        name="inn"
                        label="ИНН"
                        type="text"
                        value={this.state.inn}
                        onChange={this.handleInputChange}
                        mask={'9999999999'}
                      />
                      {errors.inn && <p className="text-red-500 text-sm">{errors.inn}</p>}

                      <AppInput
                        id="opening_date"
                        name="opening_date"
                        label="Дата открытия"
                        type="date"
                        value={this.state.opening_date}
                        onChange={this.handleInputChange}
                      />
                      {errors.opening_date && (
                        <p className="text-red-500 text-sm">{errors.opening_date}</p>
                      )}
                    </div>

                    <div className="col-span-12 lg:col-span-6 space-y-4">
                      <AppInput
                        id="payment_account"
                        name="payment_account"
                        label="Расчетный счет"
                        type="text"
                        value={this.state.payment_account}
                        onChange={this.handleInputChange}
                        mask={PAYMENT_ACCOUNT_MASK}
                      />
                      {errors.payment_account && (
                        <p className="text-red-500 text-sm">{errors.payment_account}</p>
                      )}

                      <AppInput
                        id="corr_account"
                        name="corr_account"
                        label="Корреспондентский счет"
                        type="text"
                        value={this.state.corr_account}
                        onChange={this.handleInputChange}
                        mask={CORR_ACCOUNT_MASK}
                      />
                      {errors.corr_account && (
                        <p className="text-red-500 text-sm">{errors.corr_account}</p>
                      )}

                      <AppInput
                        id="bank_bik"
                        name="bank_bik"
                        label="БИК"
                        type="text"
                        value={this.state.bank_bik}
                        onChange={this.handleInputChange}
                        mask={BIK_MASK}
                      />
                      {errors.bank_bik && <p className="text-red-500 text-sm">{errors.bank_bik}</p>}
                    </div>
                  </div>

                  <div className="mt-4 w-full flex flex-row justify-between">
                    <div className={`ml-auto`}>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-none px-4 py-2 text-sm font-medium text-white focus:outline-none bg-primaryGradient"
                        onClick={() => this.handleSubmit()}
                      >
                        Добавить банковский счет
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default AppNewBankAccountDialog;
