import React, { useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import AppLogo from '../AppLogo';
import AppHeaderBtn from '../AppHeaderBtn';
import AppSignOutBtn from '../AppSignOutBtn';
import AppNotifications from '../AppNotifications';
import { UserContext } from '../../../store/user';

const AppHeader: React.FC = () => {
  const { is_authed, is_moderator, role, email } = useContext(UserContext);

  return (
    <Disclosure as="header" className="drop-shadow-md bg-primary z-10">
      {({ open }) => (
        <>
          <div className="px-5 text-white">
            <div className="flex h-28 justify-between items-center">
              {/* Logo Section */}
              <div className="flex items-center">
                <AppLogo />
              </div>

              {/* Desktop Menu */}
              <div className="hidden xl:flex xl:items-center xl:space-x-4">
                {is_authed && is_moderator && (
                  <>
                    <AppHeaderBtn to="/admin" title="Обработка заявок" icon="far fa-tasks" />
                    <AppHeaderBtn
                      to="/admin/summary_registry"
                      title="Сводный реестр"
                      icon="far fa-folder-open"
                    />
                    <AppHeaderBtn
                      to="/admin/limits"
                      title="Проверка лимитов"
                      icon="far fa-folder-open"
                    />
                    <AppHeaderBtn
                      to="/admin/debt"
                      title="Учет долговых обязательств"
                      icon="far fa-folder-open"
                    />
                    <AppHeaderBtn
                      to="/admin/account"
                      title="Аккаунт субъекта РФ"
                      icon="far fa-chart-area"
                      childPages={['/admin/balance', '/admin/bank_accounts']}
                    />
                    {role === 'federal' && (
                      <AppHeaderBtn
                        to="/admin/transactions_map"
                        title="Карта транзакций"
                        icon="far fa-map"
                      />
                    )}

                    <AppNotifications />
                  </>
                )}

                {is_authed && !is_moderator && (
                  <>
                    <AppHeaderBtn to="/" title="Главная страница" icon="far fa-home" />
                    <AppHeaderBtn
                      to="/my_applications"
                      title="Заявки на пожертвование"
                      icon="far fa-history"
                    />
                    <AppHeaderBtn
                      to="/new_application"
                      title="Оставить заявку на пожертвование"
                      icon="far fa-edit"
                    />
                    <AppHeaderBtn to="/account" title="Профиль" icon="far fa-user" />
                  </>
                )}
              </div>

              {/* Sign Out Button */}
              {is_authed && (
                <div className="hidden xl:flex xl:flex-col xl:items-center lg:space-x-8">
                  <p className={`text-xs text-white mb-2`}>{email}</p>
                  <AppSignOutBtn />
                </div>
              )}

              {/* Mobile Menu Button */}
              <div className="xl:hidden flex items-center">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-primary hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <span className="sr-only">Открыть меню</span>
                  {open ? (
                    <i className="fal fa-times" aria-hidden="true" />
                  ) : (
                    <i className="fas fa-bars" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="xl:hidden bg-white text-primary rounded-md">
            <div className="flex flex-col space-y-2 px-4 py-2 float-right">
              {is_authed && is_moderator && (
                <>
                  <AppHeaderBtn to="/admin" title="Обработка заявок" icon="far fa-tasks" />
                  <AppHeaderBtn
                    to="/admin/summary_registry"
                    title="Сводный реестр"
                    icon="far fa-folder-open"
                  />
                  <AppHeaderBtn
                    to="/admin/limits"
                    title="Проверка лимитов"
                    icon="far fa-folder-open"
                  />
                  <AppHeaderBtn
                    to="/admin/debt"
                    title="Учет долговых обязательств"
                    icon="far fa-folder-open"
                  />
                  <AppHeaderBtn
                    to="/admin/account"
                    title="Аккаунт субъекта РФ"
                    icon="far fa-chart-area"
                  />
                  {role === 'federal' && (
                    <AppHeaderBtn
                      to="/admin/transactions_map"
                      title="Карта транзакций"
                      icon="far fa-map"
                    />
                  )}
                  <AppNotifications />
                </>
              )}

              {is_authed && !is_moderator && (
                <>
                  <AppHeaderBtn to="/" title="Главная страница" icon="far fa-home" />
                  <AppHeaderBtn
                    to="/my_applications"
                    title="Заявки на пожертвование"
                    icon="far fa-history"
                  />
                  <AppHeaderBtn
                    to="/new_application"
                    title="Оставить заявку на пожертвование"
                    icon="far fa-edit"
                  />
                  <AppHeaderBtn to="/account" title="Профиль" icon="far fa-user" />
                </>
              )}

              {is_authed && <AppSignOutBtn />}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AppHeader;
