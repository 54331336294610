import React, { Component } from 'react';
import styles from './AppInput.module.scss';
import InputMask from 'react-input-mask';

interface FloatingLabelInputProps {
  id: string;
  label: string;
  name: string;
  type?: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mask?: string;
  autocomplete?: boolean;
  readonly?: boolean;
  min?: number;
  max?: number;
}

interface FloatingLabelInputState {
  isFocused: boolean;
}

class FloatingLabelInput extends Component<FloatingLabelInputProps, FloatingLabelInputState> {
  constructor(props: FloatingLabelInputProps) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onLabelClick = () => {
    this.setState({ isFocused: true });
  };

  render() {
    const { id, label, type, value, name, onChange, mask, autocomplete, readonly } = this.props;
    const { isFocused } = this.state;
    let active = false;

    if (typeof value !== 'number') {
      active = isFocused || value?.length > 0;
    }

    if (type === 'date') {
      active = true;
    }

    if (type === 'number') {
      active = true;
    }

    return (
      <div className="relative w-full overflow-hidden">
        <InputMask
          mask={mask || ''}
          data-testid="input"
          id={id}
          name={name}
          type={type || 'text'}
          value={value}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={onChange}
          className={`block text-black w-full px-4 pt-3 pb-2 border border-gray-300 
            focus:outline-none focus:border-primary rounded-lg bg-white
            text-lg font-semibold focus:ring-0
            ${styles.minHeight60}
            ${active ? '' : ''}`}
          autoComplete={autocomplete ? '' : 'off'}
          readOnly={readonly}
          maskChar={''}
          min={this.props.min}
          max={this.props.type === 'date' ? '2999-12-31' : this.props.max}
        />

        <label
          data-testid="floating-label"
          htmlFor={id}
          className={`w-full text-ellipsis overflow-hidden whitespace-nowrap absolute left-0 px-4 py-1 text-label transition-all duration-200 uppercase font-semibold ${
            active ? 'top-0 text-xs' : 'top-1/4 text-base'
          }`}
          onClick={() => this.onLabelClick()}
        >
          {label}
        </label>
      </div>
    );
  }
}

export default FloatingLabelInput;
